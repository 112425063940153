<template>
  <el-input-number
          id="inputNumber"
          ref="inputNumber"
          v-model="fieldValue"
          :min="min"
          :max="max"
          @change="handleChange"
  />
</template>
<script>
  export default {
    props: {
      initialValue: {
        type: Number,
        required: false,
        default: 0
      },
      min: {
        type: Number,
        required: false,
        default: 0
      },
      max: {
        type: Number,
        required: false,
        default: 999
      }
    },
    data () {
      return {
        fieldValue : 0,
        taskDelayed : 0,
        isHeld : false
      };
    },
    watch: {
      initialValue: function(){
        this.fieldValue = this.initialValue;
      }
    },
    mounted (){
      this.fieldValue = this.initialValue;

      const incrementButton = this.$refs.inputNumber.$el.querySelector('.el-input-number__increase');
      const decrementButton = this.$refs.inputNumber.$el.querySelector('.el-input-number__decrease');
      
      incrementButton.addEventListener('mousedown', this.handleButtonHold, true);
      decrementButton.addEventListener('mousedown', this.handleButtonHold, true);
      incrementButton.addEventListener('mouseup', this.handleButtonRelease, true);
      decrementButton.addEventListener('mouseup', this.handleButtonRelease, true);
    },
    destroyed () {
      const incrementButton = this.$refs.inputNumber.$el.querySelector('.el-input-number__increase');
      const decrementButton = this.$refs.inputNumber.$el.querySelector('.el-input-number__decrease');
      
      incrementButton.removeEventListener('mousedown', this.handleButtonHold, true);
      decrementButton.removeEventListener('mousedown', this.handleButtonHold, true);
      incrementButton.removeEventListener('mouseup', this.handleButtonRelease, true);
      decrementButton.removeEventListener('mouseup', this.handleButtonRelease, true);
    },
    methods: {
      handleChange (value) {
        const context = this;

        if (this.taskDelayed != 0){ clearTimeout(this.taskDelayed); }
        this.taskDelayed = setTimeout(() => { context.riseChangeEvent(); },500);
      },
      riseChangeEvent (){
        this.$emit('change', this.fieldValue);
      },
      handleButtonHold (event){
        event.preventDefault();
        event.stopImmediatePropagation();
        if (this.isHeld){
          return;
        } else {
          this.isHeld = true;
          if (event.target.classList.contains('el-input-number__increase')){
            this.fieldValue++;
          } else {
            this.fieldValue--;
          }
        }
      },
      handleButtonRelease (event){
        event.preventDefault();
        event.stopImmediatePropagation();
        if (this.isHeld){
          this.isHeld = false;
          this.handleChange();
        }
      }
    }
  };
</script>

<style >
#inputNumber.form-control>.el-input-number__increase{
    border-right: 0px !important;
    margin-top: 10px;
}
#inputNumber.form-control>.el-input-number__decrease{
    border-left: 0px !important;
    margin-top: 10px;
}
#inputNumber.p-table-cell>.el-input-number__increase{
    border-right: 0px !important;
    margin-top: 6px;
}
#inputNumber.p-table-cell>.el-input-number__decrease{
    border-left: 0px !important;
    margin-top: 6px;
}
#inputNumber>.el-input-number__increase{
    border-right: 0px !important;
    margin-top: 2px;
}
#inputNumber>.el-input-number__decrease{
    border-left: 0px !important;
    margin-top: 2px;
}
div#inputNumber.form-control.el-input-number>div.el-input>input.el-input__inner{
height: -webkit-fill-available;
}
div#inputNumber.form-control.el-input-number {
width: auto !important;
    min-width: 140px !important;
}

div#inputNumber.p-table-cell.el-input-number>div.el-input>input.el-input__inner{
height: -webkit-fill-available;
}
div#inputNumber.p-table-cell.el-input-number {
  width: auto !important;
  min-width: 140px !important;
}
</style>
