var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "virtualroom-component", attrs: { direction: "vertical" } },
    [
      _c(
        "section-content",
        {
          attrs: {
            "has-icon": true,
            "icon-progress": _vm.progress.virtualRoomOption,
            "icon-text": "1",
          },
        },
        [
          _c("section-title", {
            attrs: {
              title: _vm.$root.strings.registrationFormSettings._title,
              subtitle: _vm.$root.strings.registrationFormSettings._subtitle,
            },
          }),
          _vm._v(" "),
          _c("button-tab", {
            attrs: {
              tabs: _vm.virtualRoomOptionTabs,
              "initial-tab": _vm.virtualRoomOption,
            },
            on: { tabChanged: _vm.onVirtualRoomOptionChanged },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "component-fade-fast", mode: "out-in", appear: "" } },
        [
          _vm.virtualRoomOption === "EXTERNAL_VIRTUAL_ROOM"
            ? _c(
                "section-content",
                {
                  attrs: {
                    "has-icon": true,
                    "icon-progress": _vm.progress.externalRoom,
                    "icon-text": "2",
                  },
                },
                [
                  _c("section-title", {
                    attrs: {
                      title:
                        _vm.$root.strings.registrationFormSettings
                          ._externalRoomTitle,
                      "show-separator": false,
                    },
                  }),
                  _vm._v(" "),
                  _c("custom-input", {
                    attrs: {
                      placeholder:
                        _vm.$root.strings.registrationFormSettings
                          ._externalRoomSubtitle,
                    },
                    model: {
                      value: _vm.virtualVenue,
                      callback: function ($$v) {
                        _vm.virtualVenue =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "virtualVenue",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "btn gray-btn save",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return (function () {
                            return _vm.updateEvent({
                              virtualVenue: _vm.virtualVenue,
                            })
                          })($event)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$root.strings.commonActions.save) +
                          "\n      "
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm.virtualRoomOption === "INTERNAL_REGISTRATION_FORM"
            ? _c(
                "section-content",
                {
                  attrs: {
                    "has-icon": true,
                    "icon-progress": _vm.progress.internalRoom,
                    "icon-text": "2",
                  },
                },
                [
                  _c("section-title", {
                    attrs: {
                      title:
                        _vm.$root.strings.registrationFormSettings
                          ._internalRoomTitle,
                      "show-separator": false,
                    },
                  }),
                  _vm._v(" "),
                  _c("button-tab", {
                    attrs: {
                      tabs: _vm.templateTabOptionTabs,
                      "initial-tab": _vm.templateTabOption,
                      "persist-selection": false,
                    },
                    on: { tabChanged: _vm.onTemplateTabChanged },
                  }),
                  _vm._v(" "),
                  _vm.virtualRoomPreview
                    ? _c("section-title", {
                        staticStyle: { "margin-top": "50px" },
                        attrs: {
                          title:
                            _vm.$root.strings.registrationFormSettings
                              ._previewTitle,
                          "show-separator": false,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.virtualRoomPreview
                    ? _c(
                        "el-row",
                        { staticClass: "form-field-row" },
                        [
                          _c(
                            "el-col",
                            {
                              staticClass: "embedded-html-container",
                              attrs: { span: 24 },
                            },
                            [
                              _c("iframe", {
                                staticClass: "embedded-html",
                                attrs: {
                                  id: "virtualRoomPreview",
                                  srcdoc: _vm.virtualRoomPreview,
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    [
                      _vm.virtualRoomPreview
                        ? _c("el-col", { attrs: { span: 24 } }, [
                            _c(
                              "a",
                              {
                                staticClass: "btn gray-btn save",
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.loadTemplateBuilder($event)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$root.strings.commonActions.edit
                                    ) +
                                    "\n          "
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "component-fade-fast", mode: "out-in", appear: "" } },
        [
          _vm.virtualRoomPreview &&
          _vm.virtualRoomOption === "INTERNAL_REGISTRATION_FORM"
            ? _c(
                "section-content",
                {
                  attrs: {
                    "has-icon": true,
                    "icon-progress": _vm.progress.meetingTime,
                    "icon-text": "3",
                  },
                },
                [
                  _c("section-title", {
                    attrs: {
                      title:
                        _vm.$root.strings.registrationFormSettings
                          ._meetingTimeTitle,
                      subtitle:
                        _vm.$root.strings.registrationFormSettings
                          ._meetingTimeSubtitle,
                      "show-separator": false,
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { staticStyle: { margin: "0" } },
                    [
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c(
                          "a",
                          {
                            staticClass: "btn gray-btn save",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.instantMeeting($event)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$root.strings.registrationFormSettings
                                    ._instantMeeting
                                ) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    {
                      staticStyle: { padding: "10px 0", margin: "0" },
                      attrs: { gutter: 6 },
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$root.strings.registrationFormSettings
                                ._meetingTimeSubtitle2
                            ) +
                            "\n        "
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { staticStyle: { margin: "0" }, attrs: { gutter: 10 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c("el-date-picker", {
                            staticClass: "new-form-control",
                            attrs: {
                              type: "date",
                              align: "left",
                              size: "large",
                              placeholder: "Start date",
                              format: "dd/MM/yyyy",
                              "value-format": "dd/MM/yyyy",
                              "popper-class": "default-popper",
                            },
                            model: {
                              value: _vm.virtualRoomStartDate,
                              callback: function ($$v) {
                                _vm.virtualRoomStartDate = $$v
                              },
                              expression: "virtualRoomStartDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c("el-time-select", {
                            staticClass: "new-form-control",
                            attrs: {
                              "picker-options": {
                                start: "08:30",
                                step: "00:05",
                                end: "19:30",
                              },
                              placeholder: "Start time",
                              "popper-class": "default-popper",
                            },
                            model: {
                              value: _vm.virtualRoomStartTime,
                              callback: function ($$v) {
                                _vm.virtualRoomStartTime = $$v
                              },
                              expression: "virtualRoomStartTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "new-form-control",
                              attrs: {
                                placeholder: "Duration",
                                "popper-class": "default-popper",
                              },
                              model: {
                                value: _vm.virtualRoomDuration,
                                callback: function ($$v) {
                                  _vm.virtualRoomDuration = $$v
                                },
                                expression: "virtualRoomDuration",
                              },
                            },
                            _vm._l(_vm.durationList, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: {
                                  label:
                                    item.value +
                                    " " +
                                    _vm.$root.strings.registrationFormSettings[
                                      item.labelKey
                                    ],
                                  value: item.value,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "new-form-control",
                              attrs: {
                                filterable: "",
                                placeholder: "Timezone",
                                "popper-class": "default-popper",
                              },
                              model: {
                                value: _vm.virtualRoomTimezone,
                                callback: function ($$v) {
                                  _vm.virtualRoomTimezone = $$v
                                },
                                expression: "virtualRoomTimezone",
                              },
                            },
                            _vm._l(_vm.timezonesList, function (item, index) {
                              return _c("el-option", {
                                key: "timezoneIndx" + index,
                                attrs: {
                                  label: item.text,
                                  value: "timezoneIndx" + index,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { staticStyle: { margin: "10px 0" } },
                    [
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c("span", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$root.strings.registrationFormSettings
                                  ._scheduleRoomHalfAdvance
                              ) +
                              "\n          "
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { staticStyle: { margin: "10px 0" } },
                    [
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c(
                          "a",
                          {
                            staticClass: "btn gray-btn save",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.scheduleRooms($event)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$root.strings.registrationFormSettings
                                    ._scheduleRoom
                                ) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("section-title", {
                    staticStyle: { "margin-top": "50px" },
                    attrs: {
                      title:
                        _vm.$root.strings.registrationFormSettings
                          ._moreSettingsTitle,
                      "show-separator": false,
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    {
                      staticClass:
                        "zero-margin-row keep-everything-centered margin-bottom-10",
                      attrs: { gutter: 10 },
                    },
                    [
                      _c("el-col", { attrs: { span: 5 } }, [
                        _c("span", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$root.strings.registrationFormSettings
                                  ._pageSlug + " :"
                              ) +
                              "\n          "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 1 } },
                        [
                          _c("custom-checkbox", {
                            on: { change: _vm.toggleCustomPageSlug },
                            model: {
                              value: _vm.hasCustomSlug,
                              callback: function ($$v) {
                                _vm.hasCustomSlug = $$v
                              },
                              expression: "hasCustomSlug",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 18 } }, [
                        _c(
                          "p",
                          { staticStyle: { margin: "0", padding: "0" } },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$root.strings.registrationFormSettings
                                    ._pageSlugExplanation
                                ) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.hasCustomSlug
                    ? _c(
                        "el-row",
                        {
                          staticClass:
                            "zero-margin-row keep-everything-centered margin-bottom-10",
                          attrs: { gutter: 10 },
                        },
                        [
                          _c(
                            "el-col",
                            {
                              staticStyle: { "padding-bottom": "10px" },
                              attrs: { span: 10, offset: 5 },
                            },
                            [
                              _c("custom-input", {
                                model: {
                                  value: _vm.pageSlug,
                                  callback: function ($$v) {
                                    _vm.pageSlug =
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                  },
                                  expression: "pageSlug",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: 9 } }, [
                            _c(
                              "a",
                              {
                                staticClass: "btn gray-btn save",
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.setPageSlug()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$root.strings.registrationFormSettings
                                      ._applyPageSlug
                                  )
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    {
                      staticClass:
                        "zero-margin-row keep-everything-centered margin-bottom-10",
                      attrs: { gutter: 10 },
                    },
                    [
                      _c("el-col", { attrs: { span: 5 } }, [
                        _c("span", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$root.strings.registrationFormSettings
                                  ._customThankYou + " :"
                              ) +
                              "\n          "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 1 } },
                        [
                          _c("custom-checkbox", {
                            on: { change: _vm.toggleCustomThankYou },
                            model: {
                              value: _vm.hasCustomThankYou,
                              callback: function ($$v) {
                                _vm.hasCustomThankYou = $$v
                              },
                              expression: "hasCustomThankYou",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 18 } }, [
                        _c(
                          "p",
                          { staticStyle: { margin: "0", padding: "0" } },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$root.strings.registrationFormSettings
                                    ._customThankYouExplanation
                                ) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.hasCustomThankYou
                    ? _c(
                        "el-row",
                        {
                          staticClass:
                            "zero-margin-row keep-everything-centered margin-bottom-10",
                          attrs: { gutter: 10 },
                        },
                        [
                          _c(
                            "el-col",
                            {
                              staticStyle: { "padding-bottom": "10px" },
                              attrs: { span: 15, offset: 1 },
                            },
                            [
                              _c("custom-input", {
                                attrs: {
                                  placeholder:
                                    _vm.$root.strings.registrationFormSettings
                                      ._customThankYouPlaceholder,
                                },
                                model: {
                                  value: _vm.customThankYou,
                                  callback: function ($$v) {
                                    _vm.customThankYou =
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                  },
                                  expression: "customThankYou",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c(
                              "a",
                              {
                                staticClass: "btn gray-btn save",
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.updateCustomThankYou($event)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$root.strings.registrationFormSettings
                                      ._applyPageSlug
                                  )
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    {
                      staticClass:
                        "zero-margin-row keep-everything-centered margin-bottom-10",
                      attrs: { gutter: 10 },
                    },
                    [
                      _c("el-col", { attrs: { span: 5 } }, [
                        _c("span", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$root.strings.registrationFormSettings
                                  ._hideLoginBar + " :"
                              ) +
                              "\n          "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 1 } },
                        [
                          _c("custom-checkbox", {
                            on: { change: _vm.toggleHideLoginBar },
                            model: {
                              value: _vm.hideLoginBar,
                              callback: function ($$v) {
                                _vm.hideLoginBar = $$v
                              },
                              expression: "hideLoginBar",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 18 } }, [
                        _c(
                          "p",
                          { staticStyle: { margin: "0", padding: "0" } },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$root.strings.registrationFormSettings
                                    ._hideLoginBarExplanation
                                ) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    {
                      staticClass:
                        "zero-margin-row keep-everything-centered margin-bottom-10",
                      attrs: { gutter: 10 },
                    },
                    [
                      _c("el-col", { attrs: { span: 5 } }, [
                        _c("span", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$root.strings.registrationFormSettings
                                  ._optionalEmail + " :"
                              ) +
                              "\n          "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 1 } },
                        [
                          _c("custom-checkbox", {
                            on: { change: _vm.toggleHasOptionalEmail },
                            model: {
                              value: _vm.hasOptionalEmail,
                              callback: function ($$v) {
                                _vm.hasOptionalEmail = $$v
                              },
                              expression: "hasOptionalEmail",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-col", { attrs: { span: 18 } }, [
                        _c(
                          "p",
                          { staticStyle: { margin: "0", padding: "0" } },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$root.strings.registrationFormSettings
                                    ._optionalEmailExplanation
                                ) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "component-fade-fast", mode: "out-in", appear: "" } },
        [
          _vm.virtualRoomPreview &&
          _vm.virtualRoomOption === "INTERNAL_REGISTRATION_FORM"
            ? _c(
                "section-content",
                {
                  attrs: {
                    "has-icon": true,
                    "icon-progress": _vm.progress.pagePublicity,
                    "icon-text": "4",
                  },
                },
                [
                  _c("section-title", {
                    attrs: {
                      title:
                        _vm.$root.strings.registrationFormSettings
                          ._createdRoomsTitle,
                      subtitle:
                        _vm.$root.strings.registrationFormSettings
                          ._createdRoomsSubtitle,
                      "show-separator": false,
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "data-table",
                    {
                      ref: "table",
                      attrs: {
                        "get-data": _vm.getRoomTableData,
                        "filter-enabled": false,
                        "show-pagination": false,
                        columns: _vm.tableColumns,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "lock",
                            fn: function (ref) {
                              var row = ref.row
                              return _c(
                                "span",
                                {},
                                [
                                  _c("el-button", {
                                    attrs: {
                                      icon:
                                        row.locked === true
                                          ? "el-icon-lock"
                                          : "el-icon-unlock",
                                      circle: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.toggleRoomLock(
                                          row.id,
                                          !row.locked
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            },
                          },
                          {
                            key: "sessionId",
                            fn: function (ref) {
                              var row = ref.row
                              return _c("span", {}, [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(row.customSessionId) +
                                    " (" +
                                    _vm._s(row.type) +
                                    ")\n        "
                                ),
                              ])
                            },
                          },
                          {
                            key: "publisherPin",
                            fn: function (ref) {
                              var row = ref.row
                              return _c("span", {}, [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(
                                      row.type !== "MEETING" &&
                                        row.type !== "VIRTUALSHOWROOM"
                                        ? row.publisherPin
                                        : ""
                                    ) +
                                    "\n        "
                                ),
                              ])
                            },
                          },
                          {
                            key: "status",
                            fn: function (ref) {
                              var row = ref.row
                              return _c("span", {}, [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm.videoRoomStatus[row.status]) +
                                    "\n        "
                                ),
                              ])
                            },
                          },
                          {
                            key: "callStatus",
                            fn: function (ref) {
                              var row = ref.row
                              return _c(
                                "span",
                                {},
                                [
                                  _vm.isExhibitor ||
                                  row.callStatus === "ENDED" ||
                                  row.status === "TO_BE_CREATED" ||
                                  row.status === "TERMINATED"
                                    ? _c("span", [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(
                                              _vm.videoCallStatus[
                                                row.callStatus
                                              ]
                                            ) +
                                            "\n          "
                                        ),
                                      ])
                                    : _c(
                                        "el-select",
                                        {
                                          staticClass: "new-form-control",
                                          attrs: {
                                            "popper-class": "default-popper",
                                            size: "large",
                                          },
                                          on: {
                                            change: function (e) {
                                              return _vm.updateRoomCallStatus(
                                                row.id,
                                                e
                                              )
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm.videoCallStatus[
                                                row.callStatus
                                              ],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.videoCallStatus,
                                                row.callStatus,
                                                $$v
                                              )
                                            },
                                            expression:
                                              "videoCallStatus[row.callStatus]",
                                          },
                                        },
                                        _vm._l(
                                          Object.entries(_vm.videoCallStatus),
                                          function (ref) {
                                            var key = ref[0]
                                            var label = ref[1]
                                            return _c("el-option", {
                                              key: key,
                                              attrs: {
                                                label: label,
                                                value: key,
                                                disabled: false,
                                                hidden: false,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                ],
                                1
                              )
                            },
                          },
                          {
                            key: "goToRoom",
                            fn: function (ref) {
                              var row = ref.row
                              return _c("span", {}, [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: _vm.exhibitorUrl + row.id,
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm.$root.strings
                                            .registrationFormSettings._openRoom
                                        ) +
                                        "\n          "
                                    ),
                                  ]
                                ),
                              ])
                            },
                          },
                        ],
                        null,
                        false,
                        3344857128
                      ),
                    },
                    [
                      _c("div", {
                        staticStyle: { height: "0" },
                        attrs: { slot: "emptyView" },
                        slot: "emptyView",
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.isExhibitor
                    ? _c(
                        "el-row",
                        {
                          staticClass:
                            "zero-margin-row keep-everything-centered",
                        },
                        [
                          _c("section-title", {
                            attrs: {
                              subtitle: "Contact Email",
                              "show-separator": false,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isExhibitor
                    ? _c(
                        "el-row",
                        {
                          staticClass:
                            "zero-margin-row keep-everything-centered",
                        },
                        [
                          _c("custom-input", {
                            attrs: { placeholder: "Contact Email" },
                            model: {
                              value: _vm.contactEmail,
                              callback: function ($$v) {
                                _vm.contactEmail =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "contactEmail",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isExhibitor
                    ? _c(
                        "el-row",
                        {
                          staticClass:
                            "zero-margin-row keep-everything-centered",
                          attrs: { gutter: 10 },
                        },
                        [
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c(
                              "a",
                              {
                                staticClass: "btn gray-btn save",
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return (function () {
                                      return _vm.updateEvent({
                                        contactEmail: _vm.contactEmail,
                                      })
                                    })($event)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$root.strings.commonActions
                                        .saveSettings
                                    ) +
                                    "\n          "
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c(
                              "a",
                              {
                                staticClass: "btn gray-btn save",
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.downloadFairboothLogs($event)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$root.strings.registrationFormSettings
                                        ._downloadlogs
                                    ) +
                                    "\n          "
                                ),
                              ]
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    {
                      staticClass: "zero-margin-row keep-everything-centered",
                      attrs: { gutter: 10 },
                    },
                    [
                      _vm.virtualRoomLink
                        ? _c("el-col", { attrs: { span: 24 } }, [
                            _c(
                              "a",
                              {
                                staticClass: "btn gray-btn save",
                                attrs: {
                                  href: _vm.virtualRoomLink,
                                  target: "_blank",
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$root.strings.registrationFormSettings
                                        ._goToPage
                                    ) +
                                    "\n          "
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { staticStyle: { margin: "0" } },
                    [
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c(
                          "a",
                          {
                            staticClass: "btn gray-btn save",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.onSaveButtonClicked($event)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.pages[_vm.selectedPageIndex] &&
                                    _vm.pages[_vm.selectedPageIndex]
                                      .pageStatus === "PUBLISHED"
                                    ? _vm.$root.strings.registrationFormSettings
                                        ._unpublishpage
                                    : _vm.$root.strings.registrationFormSettings
                                        ._publishpage
                                ) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }