<template>
  <div class="policy-action">
    <template v-if="joinAction === 'accept'">
      <div v-if="joinStatus === 1 && privacyPolicies.length > 0" class="field actions">
        <!-- Privacy policy -->
        <div class="title">
          {{ string.joinPage._privacy_policy }}
        </div>
        <div v-for="policy in privacyPolicies" :key="policy.id" class="single-action">
          <label class="control control-checkbox">
            <div v-if="policy.mandatory" style="padding-bottom: 5px;">
              {{ "(" + string.joinPage._mandatory + ")" }}
            </div>
            <div class="privacy-text" style="white-space: break-spaces;" v-html="policy.teamPrivacyPolicyId.text"></div>
            <input
              v-model="policy.checked"
              :required="policy.mandatory"
              type="checkbox"
              @change="checkPolicy(policy._id, policy.checked)"
            />
            <div class="control_indicator"></div>
          </label>
        </div>
      </div>
      <div v-if="timeSlotEnabled && joinStatus === 1 && timeSlotArray.length > 0"
        class="field actions"
      >
        <div class="title">
          {{ string.timeSlotManager._title }}
        </div>
        <div v-for="[slug, cField] in timeSlotArray" :key="slug" class="single-action">
          <label>
            {{ cField.options.question }}
            {{ cField.rsvpMandatory ? "(" + string.joinPage._mandatory + ")" : "" }}
          </label>
          <el-select
            v-model="answerList[slug]"
            popper-class="grayscale-popper"
            clearable
            :placeholder="cField.options.question"
            class="rsvp-select"
            style="display: block;"
            :class="{
              invalid: showErrors && cField.rsvpMandatory && answerList[slug].length < 1
            }"
            @change="value => onUpdateTimeSlot(customFieldDataMap, slug, value)"
          >
            <el-option
              v-for="[answerSlug, answer] in Object.entries(cField.options.answers)"
              :key="answerSlug"
              :hidden="answer.disabled || (answer.maxLimit > 0 && answer.limit >= answer.maxLimit)"
              :label="answer.label"
              :value="answerSlug"
            >
              {{ answer.label }}
            </el-option>
          </el-select>
        </div>
      </div>

      <div v-if="joinStatus === 1 && (memberCustomFieldArray.length > 0 ||customFieldArray.length > 0)" class="field actions">
        <!-- Custom Fields -->
        <h4 class="text-heading">
          {{ string.joinPage._custom_fields }}
        </h4>
        <div v-for="[slug, cField] in memberCustomFieldArray" :key="slug" class="single-action">
          <label>
            {{ cField.options.question }}
            {{ cField.rsvpMandatory ? "(" + string.joinPage._mandatory + ")" : "" }}
          </label>
          <el-select
            v-if="cField.type === 'MULTIPLE_CHOICE'"
            v-model="memberAnswerList[slug]"
            popper-class="grayscale-popper"
            multiple
            collapse-tags
            filterable
            :multiple-limit="cField.options.maxSelection"
            :placeholder="cField.options.question"
            class="rsvp-select"
            style="display: block;"
            :class="{
              invalid: showErrors && cField.rsvpMandatory && memberAnswerList[slug].length < 1
            }"
            @change="value => onUpdateMultipleChoice(memberCustomFieldDataMap,slug, value)"
          >
            <el-option
              v-for="[answerSlug, answer] in Object.entries(cField.options.answers)"
              :key="answerSlug"
              :hidden="answer.disabled || (answer.maxLimit > 0 && answer.limit >= answer.maxLimit)"
              :label="answer.label"
              :value="answerSlug"
            >
              {{ answer.label }}
            </el-option>
          </el-select>
          <input
            v-else
            v-model="memberCustomFieldDataMap[slug]"
            aria-label=""
            type="text"
            :placeholder="cField.options.question"
            required=""
            class="single-input"
            :class="{
              invalid: showErrors && cField.rsvpMandatory && !memberCustomFieldDataMap[slug]
            }"
          />
        </div>
        <div v-for="[slug, cField] in customFieldArray" :key="slug" class="single-action">
          <label>
            {{ cField.options.question }}
            {{ cField.rsvpMandatory ? "(" + string.joinPage._mandatory + ")" : "" }}
          </label>
          <div v-if="cField.type === 'TEXT_ONLY'" class="control-label ql-editor"
            :style="{textAlign: cField.options.alignment? cField.options.alignment:'left'}" v-html="`${cField.options.content}`">
          </div>
          <el-select
            v-else-if="cField.type === 'MULTIPLE_CHOICE'"
            v-model="answerList[slug]"
            popper-class="grayscale-popper"
            multiple
            collapse-tags
            filterable
            :multiple-limit="cField.options.maxSelection"
            :placeholder="cField.options.question"
            class="rsvp-select"
            style="display: block;"
            :class="{
              invalid: showErrors && cField.rsvpMandatory && answerList[slug].length < 1
            }"
            @change="value => onUpdateMultipleChoice(customFieldDataMap,slug, value)"
          >
            <el-option
              v-for="[answerSlug, answer] in Object.entries(cField.options.answers)"
              :key="answerSlug"
              :hidden="answer.disabled || (answer.maxLimit > 0 && answer.limit >= answer.maxLimit)"
              :label="answer.label"
              :value="answerSlug"
            >
              {{ answer.label }}
            </el-option>
          </el-select>
          <input v-else-if="cField.type === 'DATE'" 
            aria-label=""
            type="date"
            :required="cField.registrationFormMandatory"
            class="form-control date-select"
            :class="{invalid: showErrors && cField.registrationFormMandatory && !memberCustomFieldDataMap[slug]}"
            @change="value => onUpdateDateField(slug, value.target.value)"
          />
          <input
            v-else
            v-model="customFieldDataMap[slug]"
            aria-label=""
            type="text"
            :placeholder="cField.options.question"
            required=""
            class="single-input"
            :class="{
              invalid: showErrors && cField.rsvpMandatory && !customFieldDataMap[slug]
            }"
          />
        </div>
      </div>

      <div
        v-if="
          joinStatus === 1 &&
            !acquaintanceAdded &&
            (setMaxAcquaintanceOnJoin || setAcquaintanceInfoOnJoin) &&
            maxAcquaintance > 0
        "
        class="acquaintance-container"
      >
        <div id="setPartner">
          <div v-if="setMaxAcquaintanceOnJoin" class="numbers-row">
            <h4 class="text-heading">
              {{ string.joinPage._number_of_guests }}
            </h4>
            <div class="col-lg-12" style="font-size:18px;">
              <div class="container-numbers">
                <a class="dec button" @click="onDecrease()">
                  -
                </a>
                <span>{{ counterValue }}</span>
                <a class="inc button" @click="onIncrease()">
                  +
                </a>
              </div>
            </div>
          </div>

          <h3 v-if="maxAcquaintance > 0 && setAcquaintanceInfoOnJoin && acquaintanceInfoList.length > 0" class="text-heading">
            {{ string.joinPage._info_of_guests }}
          </h3>
          <div v-if="maxAcquaintance > 0 && setAcquaintanceInfoOnJoin" id="guest_name_form">
            <div
              v-for="(plusOne, index) in acquaintanceInfoList"
              :key="index"
              class="guest_name_form "
            >
              <div class="guest_name_title">
                <p class="text-heading">{{ string.joinPage._guest }} {{ index + 1 }}</p>
              </div>
              <div class="guest-row">
                <div class="guest-info-col">
                  <input
                    v-model="plusOne.name"
                    aria-label=""
                    type="text"
                    :placeholder="string.joinPage._guest_name + ' ' + (index + 1) + ' (' + string.joinPage._mandatory + ')'"
                    required=""
                    class="single-input"
                  />
                </div>
                <div class="guest-info-col">
                  <input
                    v-model="plusOne.surname"
                    aria-label=""
                    type="text"
                    :placeholder="string.joinPage._guest_surname + ' ' + (index + 1) + ' (' + string.joinPage._mandatory + ')'"
                    required=""
                    class="single-input"
                  />
                </div>
                <div class="guest-info-col">
                  <input
                    v-model="plusOne.email"
                    aria-label=""
                    type="text"
                    :placeholder="'Email ' + (index + 1)"
                    class="single-input"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <a v-if="joinStatus === 1" class="confirm-button" @click="joinAction === 'accept' ? confirmAccept() : confirmDecline()">
      {{ joinAction === 'accept' ? string.joinPage._join_confirm : string.joinPage._join_confirm_decline }}
    </a>
    
    <!-- <div
      v-if="
        joinStatus === 0 &&
          !acquaintanceAdded &&
          (setMaxAcquaintanceOnJoin || setAcquaintanceInfoOnJoin) &&
          maxAcquaintance > 0
      "
      class="acquaintance-container"
    >
      <div id="setPartner">
        <div v-if="setMaxAcquaintanceOnJoin" class="numbers-row">
          <h4 class="text-heading">
            {{ string.joinPage._number_of_guests }}
          </h4>
          <div class="col-lg-12" style="font-size:18px;">
            <div class="container-numbers">
              <a class="dec button" @click="onDecrease()">
                -
              </a>
              <span>{{ counterValue }}</span>
              <a class="inc button" @click="onIncrease()">
                +
              </a>
            </div>
          </div>
        </div>

        <h3 v-if="maxAcquaintance > 0 && setAcquaintanceInfoOnJoin" class="text-heading">
          {{ string.joinPage._info_of_guests }}
        </h3>
        <div v-if="maxAcquaintance > 0 && setAcquaintanceInfoOnJoin" id="guest_name_form">
          <div
            v-for="(plusOne, index) in acquaintanceInfoList"
            :key="index"
            class="guest_name_form "
          >
            <div class="guest_name_title">
              <p class="text-heading">{{ string.joinPage._guest }} {{ index + 1 }}</p>
            </div>
            <div class="guest-row">
              <div class="guest-info-col">
                <input
                  v-model="plusOne.name"
                  aria-label=""
                  type="text"
                  :placeholder="string.joinPage._guest_name + ' ' + (index + 1)"
                  required=""
                  class="single-input"
                />
              </div>
              <div class="guest-info-col">
                <input
                  v-model="plusOne.surname"
                  aria-label=""
                  type="text"
                  :placeholder="string.joinPage._guest_surname + ' ' + (index + 1)"
                  required=""
                  class="single-input"
                />
              </div>
              <div class="guest-info-col">
                <input
                  v-model="plusOne.email"
                  aria-label=""
                  type="text"
                  :placeholder="'Email ' + (index + 1)"
                  required=""
                  class="single-input"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <a id="partnerSubmit" class="confirm-button" @click="beforeSetAcquaintance()">
        {{ string.joinPage._join_confirm }}
      </a>
    </div> -->
    <!-- <a
      v-if="
        ((joinStatus === 0 &&
          acquaintanceAdded &&
          (setMaxAcquaintanceOnJoin || setAcquaintanceInfoOnJoin)) ||
          (joinStatus === 0 && !(setMaxAcquaintanceOnJoin || setAcquaintanceInfoOnJoin)) ||
          joinStatus === 3) &&
          virtualVenue !== ''
      "
      class="confirm-button"
      :href="virtualVenue"
      target="_blank"
    >
      Go to virtual venue
    </a>
    <a
      v-if="
        ((joinStatus === 0 &&
          acquaintanceAdded &&
          (setMaxAcquaintanceOnJoin || setAcquaintanceInfoOnJoin)) ||
          (joinStatus === 0 && !(setMaxAcquaintanceOnJoin || setAcquaintanceInfoOnJoin)) ||
          joinStatus === 3) &&
          virtualroomCode !== ''
      "
      class="confirm-button"
      :href="virtualroomCode"
      target="_blank"
    >
      Go to virtual venue
    </a> -->
  </div>
</template>
<script>
import moment from 'moment';

export default {
  props: {
    /**
     * 0 Ok
     * 1 Waiting
     * 2 Declined_OK
     * 3 Already_Joined
     * 4 Already_Declined
     * 5 Bad Token
     * 6 Event not found
     * 7 Event is full
     * 8 Guest not found
     * 9 Policies are not accepted
     * 10 Unhandled
     */
    joinAction: {
      type: String,
      required: true
    },
    joinStatus: {
      type: Number,
      required: true,
      default: 0
    },
    privacyPolicies: {
      type: Array,
      required: false,
      default: function() {
        return [];
      }
    },
    acceptedPolicies: {
      type: Array,
      required: false,
      default: function() {
        return [];
      }
    },
    maxAcquaintance: {
      type: Number,
      required: false,
      default: 0
    },
    acquaintanceAdded: {
      type: Boolean,
      required: false,
      default: true
    },
    setMaxAcquaintanceOnJoin: {
      type: Boolean,
      required: false,
      default: false
    },
    setAcquaintanceInfoOnJoin: {
      type: Boolean,
      required: false,
      default: false
    },
    timeSlotMap: {
      type: Object,
      required: false,
      default: () => {}
    },
    timeSlotEnabled: {
      type: Boolean,
      required: false,
      default: false
    },
    customFieldMap: {
      type: Object,
      required: false,
      default: () => {}
    },
    customFieldDataMap: {
      type: Object,
      required: false,
      default: () => {}
    },
    memberCustomFieldMap: {
      type: Object,
      required: false,
      default: () => {}
    },
    memberCustomFieldDataMap: {
      type: Object,
      required: false,
      default: () => {}
    },
    virtualVenue: {
      type: String,
      required: false,
      default: ""
    },
    virtualroomCode: {
      type: String,
      required: false,
      default: ""
    }
  },
  data() {
    return {
      componentName: "join-header",
      string: {},
      checkedPolicies: {},
      acquaintance: 0,
      acquaintanceInfoList: [],
      showErrors: false,
      answerList: {},
      memberAnswerList: {},
      customFieldArray:[],
      memberCustomFieldArray:[],
      timeSlotArray:[]
    };
  },
  computed: {
    counterValue: function() {
      return this.acquaintance + "/" + this.maxAcquaintance;
    },
  },
  watch: {
    acceptedPolicies: {
      immediate: true,
      handler(newVal, oldVal) {
        this.checkedPolicies = {};
        for (const acceptedPolicyId of this.acceptedPolicies) {
          this.checkedPolicies[acceptedPolicyId] = true;
        }
      }
    },
    maxAcquaintance: {
      immediate: true,
      handler(newVal, oldVal) {
        this.acquaintanceInfoList = [];
        this.acquaintance = 0;
      }
    },
    customFieldMap: {
      immediate: true,
      handler(newVal, oldVal) {
        this.customFieldArray = newVal ? Object.entries(newVal) : [];
      }
    },
    memberCustomFieldMap: {
      immediate: true,
      handler(newVal, oldVal) {
        this.memberCustomFieldArray = newVal ? Object.entries(newVal) : [];
      }
    },
    timeSlotMap: {
      immediate: true,
      handler(newVal, oldVal) {
        this.timeSlotArray = this.timeSlotMap ? Object.entries(this.timeSlotMap) : [];
      }
    },
    customFieldDataMap: {
      immediate: true,
      handler(newVal, oldVal) {
        const tempAnswerList = {};
        if (newVal) {
          for (const [key, cFieldData] of Object.entries(newVal)) {
            if (Array.isArray(cFieldData)) {
              tempAnswerList[key] = cFieldData.map(answer => answer.key);
            } else if (typeof cFieldData !== "string") {
              if (cFieldData && cFieldData.key) {
                tempAnswerList[key] = cFieldData.key;
              }
            }
          }
        }
        this.answerList = tempAnswerList;
      }
    },
    memberCustomFieldDataMap: {
      immediate: true,
      handler(newVal, oldVal) {
        const tempAnswerList = {};
        if (newVal) {
          for (const [key, cFieldData] of Object.entries(newVal)) {
            if (Array.isArray(cFieldData)) {
              tempAnswerList[key] = cFieldData.map(answer => answer.key);
            } else if (typeof cFieldData !== "string") {
              if (cFieldData && cFieldData.key) {
                tempAnswerList[key] = cFieldData.key;
              }
            }
          }
        }
        this.memberAnswerList = tempAnswerList;
      }
    }
  },
  created() {
    const context = this;
    this.string = this.$root.strings;
    this.$root.bus.addListener("setLanguage", () => {
      context.string = context.$root.strings;
    });
  },
  methods: {
    onUpdateMultipleChoice(objContext, slug, value) {
      let result = [];
      if (this.customFieldMap[slug] && this.customFieldMap[slug].options.answers) {
        if (Array.isArray(value)) {
          let answer;
          for (const answerSlug of value) {
            answer = this.customFieldMap[slug].options.answers[answerSlug];
            if (answer) {
              const newAnswer = {
                key: answer.key,
                label: answer.label
              };
              result = result && result.length > 0 ? [...result, newAnswer] : [newAnswer];
            }
          }
        }
      }
      objContext[slug] = result;
    },
    onUpdateTimeSlot(objContext, slug, value) {
      let result = null;
      if (this.timeSlotMap[slug] && this.timeSlotMap[slug].options.answers) {
        const answer = this.timeSlotMap[slug].options.answers[value];
        if (answer) {
          result = {
            key: answer.key,
            label: answer.label,
            startDate: answer.startDate,
            endDate: answer.endDate,
            title: answer.title
          };
        }
      }
      objContext[slug] = result;
    },
    onUpdateDateField(slug, value) {
      console.log(value);
      console.log(slug);
      const parsedDate = moment(value).format("DD-MM-YYYY");
      this.customFieldDataMap[slug] = parsedDate;
      console.log(this.customFieldDataMap);
    },
    checkPolicy: function(policy_id, checked) {
      this.checkedPolicies[policy_id] = checked;
    },
    confirmAccept: function() {
      const context = this;

      this.showErrors = true;

      //check policies
      let policyMandatoryChecked = true;
      let areCustomFieldsValid = true;
      for (const policy of this.privacyPolicies) {
        if (policy.mandatory && !this.checkedPolicies[policy._id]) {
          policyMandatoryChecked = false;
        }
      }
      // time slot control
      if (this.timeSlotEnabled) {
        let hasTimeSlotAnswer = false;
        for (const [slug, cField] of Object.entries(this.timeSlotMap)) {
          if (this.customFieldDataMap[slug] && this.customFieldDataMap[slug].key) {
                hasTimeSlotAnswer = true;
          }
        }
        if (!hasTimeSlotAnswer) {
            this.$alert(`${this.$root.strings.customFieldsManager._selectTimeSlotWarning}`,"",{ customClass: "grayscale" });
          return;
        }
      }
      //check custom fields
      for (const [slug, cField] of Object.entries(this.customFieldMap)) {
        if (cField.rsvpMandatory) {
          switch (cField.type) {
            case "MULTIPLE_CHOICE": {
              if (!this.customFieldDataMap[slug] || this.customFieldDataMap[slug].length < 1) {
                areCustomFieldsValid = false;
              }
              break;
            }
            case "TIME_SLOT": {
              break;
            }
            case "OPEN_ANSWER":
            default: {
              if (!this.customFieldDataMap[slug] || this.customFieldDataMap[slug] === "") {
                areCustomFieldsValid = false;
              }
              break;
            }
          }
        }
      }
       //check member custom fields
      for (const [slug, cField] of Object.entries(this.memberCustomFieldMap)) {
        if (cField.rsvpMandatory) {
          switch (cField.type) {
            case "MULTIPLE_CHOICE": {
              if (!this.memberCustomFieldDataMap[slug] || this.memberCustomFieldDataMap[slug].length < 1) {
                areCustomFieldsValid = false;
              }
              break;
            }
            case "TIME_SLOT": {
              break;
            }
            case "OPEN_ANSWER":
            default: {
              if (!this.memberCustomFieldDataMap[slug] || this.memberCustomFieldDataMap[slug] === "") {
                areCustomFieldsValid = false;
              }
              break;
            }
          }
        }
      }
      if (!policyMandatoryChecked) {
        this.$alert(`${this.$root.strings.joinPage._join_alert_policy}`,"",{ customClass: "grayscale" });
        return;
      }

      if (!areCustomFieldsValid) {
        this.$alert(`${this.$root.strings.joinPage._join_alert_fields}`,"",{ customClass: "grayscale" });
        return;
      }

      let acceptedPolicies = Object.keys(this.checkedPolicies);
      acceptedPolicies = acceptedPolicies.filter(key => {
        return context.checkedPolicies[key];
      });

      if (this.setAcquaintanceInfoOnJoin && !this.isValid()) {
        return;
      }

      this.$emit("confirmAccept", {
        acceptedPolicies: acceptedPolicies,
        customFieldDataMap: this.customFieldDataMap,
        memberCustomFieldDataMap: this.memberCustomFieldDataMap,
        maxAcquaintance: this.acquaintance,
        acquaintanceInfoList: this.setAcquaintanceInfoOnJoin ? this.acquaintanceInfoList : []
      });
    },
    confirmDecline: function() {
      const context = this;

      this.$emit("confirmDecline", {});
    },
    onDecrease: function() {
      if (this.acquaintance > 0) {
        this.acquaintance -= 1;
        this.acquaintanceInfoList.pop();
      }
    },
    onIncrease: function() {
      if (this.acquaintance < this.maxAcquaintance) {
        this.acquaintance += 1;
        this.acquaintanceInfoList.push({ name: "", surname: "", email: "" });
      }
    },
    beforeSetAcquaintance: function() {
      if (this.setAcquaintanceInfoOnJoin) {
        if (this.isValid()) {
          this.$emit("setAcquaintance", {
            maxAcquaintance: this.acquaintance,
            acquaintanceInfoList: this.acquaintanceInfoList
          });
        }
      } else {
        this.$emit("setAcquaintance", {
          maxAcquaintance: this.acquaintance,
          acquaintanceInfoList: []
        });
      }
    },
    isValid: function() {
      for (const [index, plusOne] of this.acquaintanceInfoList.entries()) {
        if (!plusOne.name || plusOne.name.trim() === "") {
          this.$notify({
            title: "",
            message: "Name " + (index + 1) + " is required",
            position: "bottom-right",
            type: "error",
            duration: 3000
          });
          return false;
        } else {
          plusOne.name = plusOne.name.trim();
        }
        if (!plusOne.surname || plusOne.surname.trim() === "") {
          this.$notify({
            title: "",
            message: "Surname " + (index + 1) + " is required",
            position: "bottom-right",
            type: "error",
            duration: 3000
          });
          return false;
        } else {
          plusOne.surname = plusOne.surname.trim();
        }
        
        plusOne.email = plusOne.email.trim();

      }
      return true;
    }
  }
};
</script>

<style scoped>
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~animate.css";
@import "~font-awesome/css/font-awesome.min.css";
@import "../../assets/css/v2/style.css";
@import "../../assets/css/v2/custom.css";
@import "../../assets/css/v2/join.css";


.acquaintance-container {
  padding-bottom: 5px;
}
.single-input {
  display: block;
  width: 100%;
  line-height: 40px;
  border: none;
  outline: 0;
  background: #f9f9ff;
  padding: 0 20px;
}
.single-input:focus {
  outline: 0 !important;
}
.single-input.invalid {
  box-shadow: 1px 1px 2px red;
}
.guest-row {
  display: flex;
  flex-wrap: wrap;
  /*margin-right: -15px;*/
  /*margin-left: -15px;*/
}
.guest-info-col {
  flex: 1;
  max-width: 33.33333%;
  margin: auto;
  padding: 10px;
}

.field {
  padding: 10px 0 10px 0;
  margin: 0 0 5px 0;
}
.single-action {
  padding: 5px;
}
.privacy-text {
  padding: 5px;
}

.actions {
  max-width: 50%;
  text-align: left;
  vertical-align: top;
  padding: 15px 0;
  margin: 0 auto;
}
@media (max-width: 992px) {
  .actions {
    max-width: 65%;
  }
}
@media (max-width: 872px) {
  .actions {
    max-width: 75%;
  }
}
@media (max-width: 536px) {
  .actions {
    max-width: 85%;
  }
}
@media (max-width: 367px) {
  .actions {
    max-width: 100%;
  }
}

.text-heading {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 18px;
  text-align: center;
  align-self: center;
}

.control {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 5px;
  padding-top: 3px;
  font-weight: 400;
  cursor: pointer;
  color: #555555;
}
#setPartner {
  align-content: center;
  align-items: center;
}
.container-numbers > span {
  text-align: center;
  border: 1px solid;
  border-radius: 3px;
  background: #f9f9ff;
  -webkit-border-radius: 3px;
  cursor: default;
  min-width: 100px;
}
.container-numbers {
  display: flex;
  align-items: center;
  margin: 0 0 30px;
  justify-content: center;
}
.container-numbers .button {
  color: #000;
  cursor: pointer;
  /*font-size: 25px;*/
  font-size: 30px;
  margin: 0 20px;
  text-decoration: none;
}
.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.control_indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: #e6e6e6;
  border: 0px solid #000000;
}

.control:hover input ~ .control_indicator,
.control input:focus ~ .control_indicator {
  background: #cccccc;
}

.control input:checked ~ .control_indicator {
  background: #000;
}
.control:hover input:not([disabled]):checked ~ .control_indicator,
.control input:checked:focus ~ .control_indicator {
  background: #000;
}
.control input:disabled ~ .control_indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}
.control_indicator:after {
  box-sizing: unset;
  content: "";
  position: absolute;
  display: none;
}
.control input:checked ~ .control_indicator:after {
  display: block;
}
.control-checkbox .control_indicator:after {
  left: 8px;
  top: 4px;
  width: 3px;
  height: 8px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.control-checkbox input:disabled ~ .control_indicator:after {
  border-color: #7b7b7b;
}

.policy-action {
  background-color: white;
  padding-top: 15px !important;
  padding-bottom: 10px;
}

.confirm-button {
  display: block;
  color: #777;
  border: 1px solid #000;
  background: #fff;
  width: 100%;
  max-width: 35%;
  margin: 50px auto 50px;
  padding: 13px;
  cursor: pointer;
  transition: 1s ease;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
}

.confirm-button:hover {
  color: #fff;
  background: #000;
  border: 1px solid transparent;
}

.actions .title {
  color: #000;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 30px;
}

.date-select {
  color: #7b7b7b !important;
}

.ql-editor{
  color: black !important;
}

</style>
